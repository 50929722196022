import { Stack, Text } from "framer"
import { createStore } from "https://framer.com/m/framer/store.js@^1.0.0"
import { randomColor } from "https://framer.com/m/framer/utils.js@^0.9.0"
import { useEffect } from "react"

// Store for random color background
const useStore = createStore({
    background: "#0099FF",
})

// Rotate the component 90 degrees
export function withRotate(Component) {
    return (props) => (
        <Component
            {...props}
            animate={{ rotate: 90 }}
            transition={{ duration: 2 }}
        />
    )
}

// Add a hover effect to scale up the component
export function withHover(Component) {
    return (props) => <Component {...props} whileHover={{ scale: 1.05 }} />
}

// Add saturation back on hover
export function withHoverSaturationOnly(Component) {
    return (props) => (
        <Component
            {...props}
            whileHover={{
                ...props.whileHover,
                filter: "saturate(1.2)",
            }}
            style={{
                filter: "saturate(0.0)",
                cursor: "default",
                ...props.style,
            }}
        />
    )
}

// IconParagraph Component using Phosphor Icons (Method 1: <img> SVG)
export default function IconParagraph({
    text,
    icon = "check-circle",
    color = "#4CAF50",
}) {
    const paragraphs = text.split("\n").filter((line) => line.trim() !== "")

    return (
        <Stack direction="vertical" gap={12}>
            {paragraphs.map((paragraph, index) => (
                <Stack key={index} direction="horizontal" gap={8} align="start">
                    <img
                        src={`https://phosphoricons.com/assets/${icon}-fill.svg`}
                        width="20"
                        height="20"
                        style={{ color }}
                        alt="icon"
                    />
                    <Text>{paragraph}</Text>
                </Stack>
            ))}
        </Stack>
    )
}

// Export all overrides as an object
export const overrides = {
    withRotate,
    withHover,
    withHoverSaturationOnly,
    IconParagraph,
}
